import React from "react";
import styles from './JournalFilter.module.css'
import { RootState } from '../../../store'
import store from '../../../store'

import { useSelector } from 'react-redux'
import {IJournalFilter, JournalFiltersList} from "./JournalFiltersList";
import useTranslation from "../../../locale/hooks/use-translation";

export default function JournalFilter() {
    const activeJournalFilter = useSelector((state: RootState) => state.activeJournalFilter)
    const {t} = useTranslation();

    function filterData(item: any): any {
        store.dispatch({ type: 'SET_FILTER', value: item })
    }

    return (
        <div className={styles.container}>
            <div>{t("card.journal.filters.title")}</div>
            <div className={styles.filters}>
                {
                    JournalFiltersList.map((item: IJournalFilter, index: number) => {
                        return <div className={`${activeJournalFilter.filterGroupType === item.filterGroupType ? styles.filterBtnActive : ''} ${styles.filterBtn}`} key={index} onClick={() => { filterData(item) }}>
                            <img className={styles.filterIcon} src={item.icon} alt="" />
                            <div className={styles.filterLabel}>
                                {t(item.labelKey)}
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    )
}
